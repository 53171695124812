import { CircularProgress, Stack, Typography } from '@mui/material';
import { ICompany } from '@shared/api/services/company-service';
import { AppRoutes } from '@shared/interfaces';
import { Link, useGetOne } from 'react-admin';
import { useParams } from 'react-router-dom';

import { ReactComponent as ArrowIcon } from '../../../shared/assets/icons/arrowLeft.svg';
import { LeftSide } from './left-side/left-side';
import { RightSide } from './right-side/right-side';

export const CompanyProfile = () => {
  const { id } = useParams();

  //const navigate = useNavigate()

  const { data, isLoading } = useGetOne<ICompany>('companies', { id: id! }, { onError: () => {} }); //navigate(AppRoutes.Companies) }

  if (isLoading) {
    return (
      <Stack width="100%" height="40vh" justifyContent="center" alignItems="center">
        <CircularProgress />
      </Stack>
    );
  }
  return (
    <Stack m={40} gap={16} height="100vh">
      <Link to={`${AppRoutes.Companies}`}>
        <Stack direction="row" alignItems="center" gap={7}>
          <Stack alignItems="center" justifyContent="center">
            <ArrowIcon />
          </Stack>
          <Typography variant="subtitle1" color="customGray2.dark">
            Back to companies
          </Typography>
        </Stack>
      </Link>
      <Stack direction="row" justifyContent="space-between" alignItems="flex-start" gap={32}>
        <LeftSide company={data!} />
        <RightSide company={data!} />
      </Stack>
    </Stack>
  );
};
