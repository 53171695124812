import { Box, Stack, Typography } from '@mui/material';

const AuthDivider = () => {
  return (
    <Stack direction="row" justifyContent="space-between" alignItems="center" gap={9}>
      <Box borderTop={1} borderColor="divider" flexGrow={1} />
      <Typography variant="subtitle1" color="customGray1.dark">
        or
      </Typography>
      <Box borderTop={1} borderColor="divider" flexGrow={1} />
    </Stack>
  );
};

export default AuthDivider;
