import { PhoneInProfile } from '@features/contacts/buttons/phone-button';
import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { ICompany } from '@shared/api/services/company-service';
import { copyInBuffer, getValidLink } from '@shared/libs/validations';
import { FC } from 'react';
import { useNotify } from 'react-admin';

import { ReactComponent as FacebookIcon } from '../../../../shared/assets/icons/iconFacebook.svg';
import { ReactComponent as LinkIcon } from '../../../../shared/assets/icons/iconLink.svg';
import { ReactComponent as LinkedinIcon } from '../../../../shared/assets/icons/iconLinkedin.svg';
import { ReactComponent as TwitterIcon } from '../../../../shared/assets/icons/iconTwitter.svg';

export const LeftSide: FC<{ company: ICompany }> = ({ company }) => {
  const { facebook_url, linkedin_url, name, twitter_url, website } = company;

  const notify = useNotify();

  const socialsFullName = [
    { social: 'Linkedin', url: linkedin_url, icon: LinkedinIcon },
    { social: 'Twitter', url: twitter_url, icon: TwitterIcon },
    { social: 'Company', url: website, icon: LinkIcon },
    { social: 'Facebook', url: facebook_url, icon: FacebookIcon },
  ];

  const onCopyIconHandler = (value: string) => {
    const isCopied = copyInBuffer(value);
    if (!isCopied) return;
    notify('Phone is copied', { type: 'success' });
  };

  return (
    <Stack
      direction="column"
      justifyContent="space-between"
      border={(theme) => `1px solid ${theme.palette.customGray1.light}`}
      borderRadius="8px"
      minWidth={460}
      maxWidth={600}
      width="100%"
    >
      <Stack direction="column" justifyContent="space-between" p={16}>
        <Stack direction="row" justifyContent="space-between" alignItems="center">
          <Typography
            variant="h3"
            color="text.primary"
            fontWeight={700}
            display="flex"
            flexDirection="row"
            alignItems="center"
          >
            {name}
          </Typography>

          <Stack direction="row" ml={-4} mt={-2}>
            {socialsFullName.map(
              ({ url, social, icon: Icon }) =>
                url && (
                  <Tooltip title={getValidLink(url)} key={social}>
                    <a href={getValidLink(url)} rel="noreferrer" target="_blank">
                      <IconButton sx={{ p: 4 }}>
                        <Icon />
                      </IconButton>
                    </a>
                  </Tooltip>
                )
            )}
          </Stack>
        </Stack>
      </Stack>
      <Stack
        direction="column"
        width="100%"
        borderTop={(theme) => `1px solid ${theme.palette.customGray1.light}`}
        pt={12}
      >
        <PhoneInProfile phone="+934567890" onCopyPhone={onCopyIconHandler} isEmailExist={true} />
      </Stack>
    </Stack>
  );
};
