import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { ICompany } from '@shared/api/services/company-service';
// import { AppRoutes } from '../../../shared/interfaces'
import { AppRoutes } from '@shared/interfaces';
import { getValidLink } from '@shared/libs/validations';
import { FC } from 'react';
import { Link, useRecordContext } from 'react-admin';

import { ReactComponent as FacebookIcon } from '../../../shared/assets/icons/facebook-contact.svg';
import { ReactComponent as LinkIcon } from '../../../shared/assets/icons/link-icon.svg';
import { ReactComponent as LinkedinIcon } from '../../../shared/assets/icons/linkedin-contact.svg';
import { ReactComponent as TwitterIcon } from '../../../shared/assets/icons/twitter-contact.svg';
import { IBaseFieldProps } from './types';

const CompanyNameField: FC<IBaseFieldProps> = () => {
  const record = useRecordContext<ICompany>();
  if (!record) return null;
  const { facebook_url, id, linkedin_url, name, twitter_url, website } = record;

  const socialsCompany = [
    { social: 'Site', url: website, icon: LinkIcon },
    { social: 'Linkedin', url: linkedin_url, icon: LinkedinIcon },
    { social: 'Twitter', url: twitter_url, icon: TwitterIcon },
    { social: 'Facebook', url: facebook_url, icon: FacebookIcon },
  ];

  return (
    <Stack>
      <Tooltip title={name}>
        <Link to={`${AppRoutes.Companies}/${id}/show`} onClick={(e) => e.stopPropagation()}>
          <Typography
            color="primary.main"
            variant="subtitle1"
            textOverflow="ellipsis"
            maxWidth="100%"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            {name}
          </Typography>
        </Link>
      </Tooltip>

      <Stack direction="row" ml={-4} mt={-2}>
        {socialsCompany.map(
          ({ url, social, icon: Icon }) =>
            url && (
              <Tooltip title={getValidLink(url)} key={social} onClick={(e) => e.stopPropagation()}>
                <a href={getValidLink(url)} rel="noreferrer" target="_blank">
                  <IconButton sx={{ p: 4 }}>
                    <Icon />
                  </IconButton>
                </a>
              </Tooltip>
            )
        )}
      </Stack>
    </Stack>
  );
};

export default CompanyNameField;
