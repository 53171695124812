import { Stack } from '@mui/material';
import { FC } from 'react';
import { Pagination } from 'react-admin';

const ContactPagination: FC<{ perPage?: number }> = () => {
  return (
    <Stack direction="row" alignItems="center">
      <Pagination rowsPerPageOptions={[10, 25, 50]} />
    </Stack>
  );
};

export default ContactPagination;
