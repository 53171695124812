import { Stack, Typography } from '@mui/material';
import { IContact } from '@shared/api/services/contact-service';
import { sortExperiences } from '@shared/libs/sortExperiences';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Button } from 'react-admin';

import { useContactsStore } from '../../../../../entities/contact-table';
import Experience from '../../../../../features/contacts/experience/experience';
import InfoBlock from '../../../../../features/contacts/info-block/info-block';

const EducationHistory = ({ contact }: { contact: IContact }) => {
  console.log(contact);

  const contactsStore = useContactsStore();

  const [slice, setSlice] = useState(true);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setSlice(true);
  }, [contact]);

  useEffect(() => {
    if (contactsStore.currentUpdatedContact?.education) {
      setCount(contactsStore.currentUpdatedContact?.education.length);
    } else {
      setCount(contactsStore.currentEducationHistory?.length ?? 0);
    }
  }, [contactsStore.currentUpdatedContact?.education, contactsStore.currentEducationHistory]);

  const children = (
    <>
      {contactsStore.currentUpdatedContact
        ? contactsStore.currentUpdatedContact.education.map((school, index) => (
            <Experience
              key={index}
              place={school.schoolName}
              role={school.degreeName}
              startDate={school.startDate}
              endDate={school.endDate ?? ''}
            />
          ))
        : sortExperiences(contactsStore.currentEducationHistory ?? [])
            .slice(0, slice ? 2 : undefined)
            .map((school, index) => (
              <Experience
                key={index}
                place={school.school?.name ?? ''}
                role={school.degrees?.[0] ?? ''}
                startDate={school.start_date}
                endDate={school.end_date ?? ''}
              />
            ))}

      {slice && count > 2 && (
        <Stack direction="row" justifyContent="center" alignItems="center" p={16}>
          <Button
            sx={{ width: '100%' }}
            variant="contained"
            color="secondary"
            onClick={(e) => {
              e.stopPropagation();
              setSlice((prev) => !prev);
            }}
          >
            <Typography variant="subtitle1" color="primary.main">
              Show more
            </Typography>
          </Button>
        </Stack>
      )}
    </>
  );
  if (!contactsStore.currentUpdatedContact && !contactsStore.currentEducationHistory?.length)
    return <></>;
  return <InfoBlock blockTitle={'Education history'}>{children}</InfoBlock>;
};

export default observer(EducationHistory);
