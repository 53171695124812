import {
  IContact,
  IEducationHistory,
  IUpdatedUserInfo,
  IWorkHistory,
} from '@shared/api/services/contact-service';
import { makeAutoObservable } from 'mobx';
import { createContext, useContext } from 'react';

class CompaniesStore {
  currentContact: IContact | null = null;
  currentWorkHistory: IWorkHistory[] | null = null;
  currentEducationHistory: IEducationHistory[] | null = null;
  currentUpdatedContact: IUpdatedUserInfo | null = null;
  exportContacts: string[] = [];
  currentContacts: IContact[] = [];
  savedContacts: IContact[] = [];
  exportAllSelector = false;

  total = 0;

  constructor() {
    makeAutoObservable(this);
  }

  isInExportQueue(id: string) {
    return this.exportContacts.find((contact) => contact === id) ? true : false;
  }

  toggleContactForExport(id: string) {
    if (id) {
      this.isInExportQueue(id)
        ? (this.exportContacts = this.exportContacts?.filter((contact) => contact !== id))
        : this.exportContacts?.push(id);
    }
    if (companiesStore.exportContacts.length < companiesStore.currentContacts.length) {
      this.exportAllSelector = false;
    } else {
      this.exportAllSelector = true;
    }
  }

  toggleSelectAllForExport() {
    this.exportAllSelector
      ? (this.exportContacts = this.currentContacts.map((contact) => contact.id))
      : (this.exportContacts = []);
  }

  setCurrentContact(contact: IContact | null) {
    this.currentContact = contact;
  }
  setCurrentUpdatedContact(contactUpd: IUpdatedUserInfo | null) {
    this.currentUpdatedContact = contactUpd;
  }

  setCurrentWorkHistory(workHistory: IWorkHistory[] | null) {
    this.currentWorkHistory = workHistory;
  }

  setCurrentEducationHistory(educationHistory: IEducationHistory[] | null) {
    this.currentEducationHistory = educationHistory;
  }

  addSavedContact(openedContact: IContact) {
    const contactIndex = this.savedContacts.findIndex(({ id }) => id === openedContact.id);
    if (contactIndex !== -1) {
      this.savedContacts[contactIndex] = {
        ...this.savedContacts[contactIndex],
        emails: openedContact.emails,
        phoneNumbers: openedContact.phoneNumbers,
      };
      return;
    }

    this.savedContacts.push(openedContact);
  }

  getSavedById(contactId: string) {
    return this.savedContacts.find(({ id }) => id === contactId);
  }
  getContactById(contactId: string) {
    return this.currentContacts.find(({ id }) => id === contactId);
  }

  setTotal(value: number) {
    this.total = value;
  }
}

const companiesStore = new CompaniesStore();

const Context = createContext(companiesStore);

const useCompaniesStore = () => useContext(Context);

export default useCompaniesStore;

export { companiesStore };
