import { ContactFiltersProvider } from '@features/contacts/filters/hooks/filter-context';
import { Box, Stack } from '@mui/material';
import { AppRoutes } from '@shared/interfaces';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';
import { Link } from 'react-admin';

import {
  ContactFilters,
  ContactPagination,
  SavedButton,
  TotalButton,
} from '../../features/contacts';
import ExportButton from '../../features/contacts/buttons/export-button';
import { AvatarButton } from '../../features/user';
import { ReactComponent as LogoComponent } from '../../shared/assets/icons/LogoSmall.svg';
import { FirstEnter } from './first-enter/first-enter';
import useIsNoFilters from './hooks/useIsNoFilters';
import c from './style.module.scss';

const ContactLayout = observer(
  ({
    children,
    setShowSaved,
    showSaved,
    isCompanies = false,
  }: {
    showSaved: boolean;
    setShowSaved: React.Dispatch<boolean>;
    children: React.ReactNode;
    isCompanies?: boolean;
  }) => {
    const { isFilterApplied } = useIsNoFilters();

    useEffect(() => {
      document.body.style.overflow = 'hidden';
      return () => {
        document.body.style.overflow = 'visible';
      };
    }, []);

    const contentSlot =
      !isFilterApplied && !showSaved ? (
        <FirstEnter />
      ) : (
        <>
          {children}
          <Stack direction="row" alignItems="center">
            <ContactPagination />
          </Stack>
        </>
      );

    return (
      <ContactFiltersProvider>
        <Box minHeight="100vh" width="100%" className={c.wrapper}>
          {/* filters */}
          <Stack
            flex="0 0 300px"
            width={300}
            sx={{
              borderRight: (theme) => `1px solid ${theme.palette.customGray1.light}`,
            }}
            className={c.filters}
          >
            <Box pt={24} pl={24} pb={50}>
              <Link to={AppRoutes.Contacts}>
                <LogoComponent />
              </Link>
            </Box>
            {isCompanies ? <ContactFilters /> : <ContactFilters />}
          </Stack>
          {/* header */}
          <Stack
            flex="0 0 75px"
            pb={16}
            pt={24}
            px={24}
            className={c.header}
            bgcolor="primary.contrastText"
            sx={{
              borderBottom: (theme) => `1px solid ${theme.palette.customGray1.light}`,
            }}
            direction="row"
          >
            <Stack
              direction="row"
              alignItems="center"
              gap={16}
              pr={16}
              sx={{ borderRight: (theme) => `1px solid ${theme.palette.customGray1.light}` }}
            >
              <TotalButton handleClick={() => setShowSaved(false)} isSaved={showSaved} />
              <SavedButton handleClick={() => setShowSaved(true)} isSaved={showSaved} />

              {/* <Link to={AppRoutes.Contacts}>Contacts</Link>

              <Link to={AppRoutes.Companies}>Companies</Link> */}
            </Stack>
            <Stack px={16}>
              <ExportButton />
            </Stack>
            <Box flexGrow={1} />
            <AvatarButton />
          </Stack>
          {/* data grid */}
          <Stack className={c.children} px={24} pt={24} pb={10}>
            {contentSlot}
          </Stack>
        </Box>
      </ContactFiltersProvider>
    );
  }
);

export default ContactLayout;
