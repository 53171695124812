import { Stack, Typography } from '@mui/material';

const mockInsights = [
  {
    name: 'Gmail',
    type: 'Email providers',
  },
  {
    name: 'Adobe Media Optimizer',
    type: 'Search Marketing',
  },
  {
    name: 'Google Cloud Hosting',
    type: 'Hosting',
  },
  {
    name: 'Bing Ads',
    type: 'Advertising Networks',
  },
];
export const Insights = () => {
  return (
    <Stack
      minWidth={490}
      borderRadius={(theme) => theme.spacing(8)}
      border={(theme) => `1px solid ${theme.palette.customGray1.light}`}
    >
      <Stack
        direction="row"
        justifyContent="left"
        alignItems="center"
        gap={9}
        px={16}
        py={12}
        borderBottom={(theme) => `1px solid ${theme.palette.customGray1.light}`}
      >
        <Typography variant="h4" fontWeight={700}>
          Insights
        </Typography>
      </Stack>
      <Stack direction="column" justifyContent="center" gap={12} px={16} pt={12}>
        {mockInsights.map((insight, index) => (
          <Stack
            key={index}
            direction="column"
            justifyContent="left"
            borderBottom={(theme) => `1px solid ${theme.palette.customGray1.light}`}
            pb={12}
            sx={{ ':last-child': { borderBottom: 'none' } }}
          >
            <Typography variant="subtitle1" color="text.primary">
              {insight.name}
            </Typography>
            <Typography variant="subtitle2" color="text.secondary">
              {insight.type}
            </Typography>
          </Stack>
        ))}
      </Stack>
    </Stack>
  );
};
