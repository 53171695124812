import './styles/index.scss';

import { AuthWrapper } from '@entities/auth-wrapper/auth-wrapper';
import { CompanyProfile } from '@pages/contacts/company/company-profile';
import { UserProfile } from '@pages/user-profile/user-profile';
import { AppRoutes } from '@shared/interfaces';
import { Admin, CustomRoutes, Resource } from 'react-admin';
import { QueryClient } from 'react-query';
import { BrowserRouter } from 'react-router-dom';
import { Route } from 'react-router-dom';

import { dataProvider } from '@/providers/dataProvider';

import { LoginPage } from '../pages/auth/login';
import { ContactProfile, ContactsPage } from '../pages/contacts';
import CompaniesPage from '../pages/contacts/company/companies-page';
import { PaymentPage, SubscriptionPage } from '../pages/user';
import authProvider from '../providers/authProvider';
import { AuthFlowLayout } from '../shared/ui/auth-flow-layout';
import { BaseLayout } from '../widgets/base-layout';
import { UserAccountLayout } from '../widgets/user-account-layout';
import { AuthRoute } from './hocs';
import { authRoutes } from './router';
import theme from './theme/theme';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: 1000 * 60 * 5,
      retry: false,
      refetchOnWindowFocus: false,
      cacheTime: 0,
    },
  },
});
interface IEventEmmiter {
  _eventHandlers: Record<string, Array<(...args: unknown[]) => void>>;
  on: (eventName: string, handler: (...args: unknown[]) => void) => void;
  off: (eventName: string, handler: (...args: unknown[]) => void) => void;
  emmit: (eventName: string, ...args: unknown[]) => void;
}
export const eventEmmiter: IEventEmmiter = {
  _eventHandlers: {},

  on(eventName, handler) {
    if (!this._eventHandlers) this._eventHandlers = {};
    if (!this._eventHandlers[eventName]) {
      this._eventHandlers[eventName] = [];
    }
    this._eventHandlers[eventName].push(handler);
  },

  off(eventName, handler) {
    const handlers = this._eventHandlers && this._eventHandlers[eventName];
    if (!handlers) return;
    for (let i = 0; i < handlers.length; i++) {
      if (handlers[i] === handler) {
        handlers.splice(i--, 1);
      }
    }
  },

  emmit(eventName, ...args) {
    if (!this._eventHandlers || !this._eventHandlers[eventName]) {
      return; // обработчиков для этого события нет
    }
    // вызовем обработчики
    this._eventHandlers[eventName].forEach((handler) => handler.apply(this, args));
  },
};

function App() {
  return (
    <BrowserRouter>
      <AuthWrapper>
        <Admin
          dataProvider={dataProvider}
          authProvider={authProvider}
          loginPage={LoginPage}
          disableTelemetry
          theme={theme}
          layout={BaseLayout}
          queryClient={queryClient}
        >
          <Resource
            name="contacts"
            list={ContactsPage}
            show={
              <UserAccountLayout>
                <ContactProfile />
              </UserAccountLayout>
            }
            options={{ initialFetch: false }}
          />
          {/* <Resource
            name="saved"
            show={<ContactProfile />}
            options={{ initialFetch: false }}
          /> */}
          <Resource
            name="companies"
            list={CompaniesPage}
            show={
              <UserAccountLayout>
                <CompanyProfile />
              </UserAccountLayout>
            }
            options={{ initialFetch: false }}
          />
          <CustomRoutes noLayout>
            {authRoutes.map(({ path, component: Page }) => (
              <Route
                key={path}
                path={path}
                element={
                  <AuthRoute>
                    <AuthFlowLayout>
                      <Page />
                    </AuthFlowLayout>
                  </AuthRoute>
                }
              />
            ))}
            <Route element={<UserAccountLayout />}>
              <Route path={AppRoutes.Profile} element={<UserProfile />} />
              <Route path={AppRoutes.ProfileSubscription} element={<SubscriptionPage />} />
              <Route path={AppRoutes.SubscriptionPayment} element={<PaymentPage />} />
            </Route>
          </CustomRoutes>
        </Admin>
      </AuthWrapper>
    </BrowserRouter>
  );
}

export default App;
