import { Stack } from '@mui/material';
import { ICompany } from '@shared/api/services/company-service';

import { CurrentCompany } from './current-company/current-company';
import { Insights } from './insights/insights';

export const RightSide = ({ company }: { company: ICompany }) => {
  return (
    <Stack direction="column" gap={32} width="100%" minWidth={460}>
      <CurrentCompany company={company} />
      <Insights />
    </Stack>
  );
};
