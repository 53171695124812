//import { getLinkedInName } from '../../shared/api/getLinkedInName'
import { contactService } from '@shared/api/services/contact-service';
import { observer } from 'mobx-react-lite';
import { useEffect } from 'react';

import { useContactsStore } from '../contact-table';

const UpdateWrapper = ({
  children,
  setUpdating,
  isOpen,
}: {
  children: unknown;
  setUpdating: (a: boolean) => void;
  isOpen: boolean;
}) => {
  const contactsStore = useContactsStore();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const abortController = new AbortController();

  useEffect(() => {
    // const updateByLinkedIn = async () => {
    //   try {
    //     if (!contactsStore.currentContact?.linkedin_url) return

    //     const linkedInName = getLinkedInName(contactsStore.currentContact?.linkedin_url)

    //     if (!linkedInName) return

    //     const updatedUser = await contactService.updateCurrentUser(linkedInName)

    //     contactsStore.setCurrentUpdatedContact(updatedUser)
    //     // const filteredObj = filterNullFields(updatedUser)
    //     // console.log('filterred', filteredObj)
    //     // console.log('not filtered', updatedUser)
    //   } catch (e) {
    //     console.log(e)
    //   }
    // }

    const updateWorkAndEducationHistory = async () => {
      try {
        if (!contactsStore.currentContact) return;

        const workHistory = await contactService.updateWorkHistory(contactsStore.currentContact.id);

        if (!workHistory) return;
        console.log(workHistory);

        contactsStore.setCurrentWorkHistory(workHistory);

        const educationHistory = await contactService.updateEducationHistory(
          contactsStore.currentContact.id
        );

        if (!educationHistory) return;

        contactsStore.setCurrentEducationHistory(educationHistory);
      } catch (e) {
        console.log(e);
      }
    };

    const updateContact = async () => {
      contactsStore.setCurrentUpdatedContact(null);
      contactsStore.setCurrentWorkHistory(null);
      contactsStore.setCurrentEducationHistory(null);

      if (isOpen) {
        setUpdating(true);

        await updateWorkAndEducationHistory();
        //await updateByLinkedIn()

        setUpdating(false);
      } else {
        abortController.abort();
      }
    };

    updateContact();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen, contactsStore.currentContact]);
  return <>{children}</>;
};

export default observer(UpdateWrapper);
