import { AES } from 'crypto-js';

const setTokenCookie = (name: string, token: string) => {
  const date = new Date();
  date.setFullYear(date.getFullYear() + 1);
  const encryptedToken = AES.encrypt(token, 'bruxtExtensionKey').toString();
  document.cookie = `${name}=${encryptedToken}; expires=${date.toUTCString()}; domain=.bruxt.com; path=/; Secure; SameSite=Strict;`;
};

const TokenService = {
  getLocalAccessToken() {
    const token = localStorage.getItem('accessToken');
    setTokenCookie('bruxt_extension_token', token ?? '');
    return token;
  },
  getLocalRefreshToken() {
    return localStorage.getItem('refreshToken');
  },
  updateLocalAccessToken(token: string) {
    setTokenCookie('bruxt_extension_token', token);
    localStorage.setItem('accessToken', token);
  },
  updateLocalRefreshToken(token: string) {
    setTokenCookie('bruxt_extension_refresh_token', token);
    localStorage.setItem('refreshToken', token);
  },
  deleteTokens() {
    localStorage.removeItem('accessToken');
    document.cookie =
      'bruxt_extension_token=;expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.bruxt.com; path=/; Secure; SameSite=Strict;';
    localStorage.removeItem('refreshToken');
    document.cookie =
      'bruxt_extension_refresh_token=;expires=Thu, 01 Jan 1970 00:00:01 GMT; domain=.bruxt.com; path=/; Secure; SameSite=Strict;';
  },
};

export default TokenService;
