import { useSubscriptionStore } from '@entities/subscription';
import { Box, Button, CircularProgress, Stack, Typography } from '@mui/material';
import { useUserProfile } from '@pages/user-profile/model/user-profile.store';
import { AppRoutes } from '@shared/interfaces';
import { useStripe } from '@stripe/react-stripe-js';
import { AxiosError } from 'axios';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { useNotify } from 'react-admin';
import { useNavigate } from 'react-router-dom';

import { PlanStatus } from '@/shared/api/services/plan-service/plan.interfaces';

import { handlePayment } from '../stripe-form/subscription-utils.js';

const SummaryForm = ({
  refIsShouldBlockPageRefreshing,
}: {
  refIsShouldBlockPageRefreshing: React.MutableRefObject<boolean>;
}) => {
  const stripe = useStripe();
  const subscription = useSubscriptionStore();
  const userProfile = useUserProfile();
  const [totalPrice, setTotalPrice] = useState<string | undefined>('0');
  const [isProgress, setIsProgress] = useState<boolean>(false);

  useEffect(() => {
    const updatedTotalPrice =
      subscription.totalExtendedPrice ||
      subscription.selectedPlan?.price ||
      subscription.currentSubscription?.plan.price;
    setTotalPrice(updatedTotalPrice);
  }, [
    subscription.totalExtendedPrice,
    subscription.currentSubscription?.plan.price,
    subscription.selectedPlan?.price,
  ]);

  const navigate = useNavigate();
  const notify = useNotify();
  if (!subscription.selectedPlan) return null;

  const planTitle = `${subscription.selectedPlan.name} plan`;

  // TODO move all plans checkings to subscription.model
  const isFreeSelected = subscription.selectedPlan.grade === 'standard';
  const isFreeSubscribed =
    subscription.currentSubscription === null ||
    subscription.currentSubscription?.status === PlanStatus.CANCELED;

  const isDisabled =
    (isFreeSubscribed && isFreeSelected) ||
    (!isFreeSubscribed &&
      subscription.isSelectedPlanSubscribed &&
      subscription.sliderValues.email.count ===
        subscription.subscribedPlanSilderValues.email.count &&
      subscription.sliderValues.phone.count ===
        subscription.subscribedPlanSilderValues.phone.count &&
      subscription.sliderValues.export.count ===
        subscription.subscribedPlanSilderValues.export.count);

  const currentPrice = isFreeSubscribed ? 0 : subscription.currentSubscription?.plan.price;
  const totalDueToday = +totalPrice! - +(currentPrice ?? 0);
  const displayedTotalDueToday = totalDueToday < 0 ? 0 : totalDueToday;

  // TODO move payment to subscription.model.
  const onSubmit = async () => {
    refIsShouldBlockPageRefreshing.current = true;
    subscription.state = 'pending';
    // TODO remove request for plans change state data
    if (subscription.selectedPlan?.id === subscription.freePlan?.id) {
      try {
        setIsProgress(true);
        await flowResult(subscription.cancel());
        await flowResult(subscription.getPlans());
        navigate(AppRoutes.Profile);
        setIsProgress(false);
        return;
      } catch (e: unknown) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        notify(e.response.data.message || 'Try later', { type: 'error' });
      } finally {
        refIsShouldBlockPageRefreshing.current = false;
        subscription.state = 'done';
      }
    }

    if (userProfile.cardInfo) {
      try {
        setIsProgress(true);
        await handlePayment(
          stripe,
          null,
          userProfile.cardInfo.paymentMethod,
          false,
          subscription,
          userProfile,
          navigate,
          notify,
          setIsProgress,
          refIsShouldBlockPageRefreshing
        );
        refIsShouldBlockPageRefreshing.current = false;
        subscription.state = 'done';
        return;
      } catch (e: unknown) {
        let message = 'IDK';
        if (e instanceof AxiosError) {
          message = e.response?.data.message;
        } else if (e instanceof Error || (typeof e === 'object' && e && 'message' in e)) {
          message = String(e.message);
        } else {
          message = 'Something went wrong, try later';
        }
        notify(message, { type: 'error' });
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (e.response?.data.stringCode === 'payment_failed') {
          await flowResult(subscription.cancel());
        }
      } finally {
        refIsShouldBlockPageRefreshing.current = false;
        subscription.state = 'done';
      }
    } else {
      refIsShouldBlockPageRefreshing.current = false;
      navigate(AppRoutes.SubscriptionPayment);
    }
    refIsShouldBlockPageRefreshing.current = false;
    subscription.state = 'done';
  };

  const getUpgradeBtnText = () => {
    if (isFreeSelected) {
      return isFreeSubscribed ? 'Current plan' : 'Get Free plan';
    }

    return isDisabled ? 'Current plan' : 'Upgrade';
  };

  return (
    <Box
      height={(theme) => theme.spacing(128)}
      position="fixed"
      bottom={0}
      left={0}
      right={0}
      bgcolor="#fff"
      zIndex={10000}
      boxShadow="0px -4px 20px rgba(111, 113, 122, 0.1)"
    >
      <Stack
        m="0 auto"
        maxWidth={1256}
        width="100%"
        pt={24}
        pb={40}
        px={20}
        direction="row"
        justifyContent="space-between"
      >
        <Stack gap={4}>
          <Typography color="text.secondary" variant="subtitle1">
            Summary
          </Typography>
          <Typography variant="h2"> {planTitle} </Typography>
        </Stack>
        <Stack direction="row" gap={80}>
          <Stack gap={24} direction="row" alignItems="center">
            <Typography variant="h2">
              ${isFreeSelected ? '0' : Math.round(+totalPrice!)}
              <Typography component="span" variant="h4" fontWeight={700}>
                /{subscription.selectedPlan.duration}
              </Typography>
            </Typography>
            <Box height={(theme) => theme.spacing(40)} bgcolor="customGray1.light" width="1px" />
            <Stack direction="row" gap={8}>
              <Typography variant="h2">${isFreeSelected ? 0 : displayedTotalDueToday}</Typography>
              <Typography variant="subtitle1" color="text.secondary" alignSelf="flex-end">
                Due today
              </Typography>
            </Stack>
          </Stack>
          {isProgress ? (
            <Stack height="70px" alignItems="center" justifyContent="center">
              <CircularProgress size={70} />
            </Stack>
          ) : (
            <Stack justifyContent="center">
              <Button
                variant="contained"
                sx={{ py: 11.75, px: 63.5 }}
                disabled={isDisabled}
                onClick={onSubmit}
              >
                {getUpgradeBtnText()}
              </Button>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Box>
  );
};

export default observer(SummaryForm);
