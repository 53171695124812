import { Box, Button, Dialog, Stack, Typography } from '@mui/material';
import { useUserProfile } from '@pages/user-profile/model/user-profile.store';
import { AppRoutes, UpgradeTypes } from '@shared/interfaces';
import { observer } from 'mobx-react-lite';
//import { useState } from 'react'
//import c from './UpgradePopup.module.scss'
import { useLayoutEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { eventEmmiter } from '@/app/App';

import { ReactComponent as SubIcon } from '../../../shared/assets/icons/iconSubscription.svg';

const UpgradePopup = () => {
  const userStore = useUserProfile();
  //const [anchorEl, setAnchorEl] = useState(null)
  const navigate = useNavigate();

  const [show, setShow] = useState(false);
  useLayoutEffect(() => {
    const toggle = () => {
      setShow((prev) => !prev);
    };
    eventEmmiter.on('toggle-popup', toggle);

    return () => {
      eventEmmiter.off('toggle-popup', toggle);
    };
  }, []);

  const handleClose = () => {
    userStore.toggleUpgradePopup(UpgradeTypes.VIEW);
    setShow((prev) => !prev);
    //setAnchorEl(null)
  };
  const handleUpgrade = () => {
    userStore.toggleUpgradePopup();
    setShow((prev) => !prev);
    navigate(AppRoutes.ProfileSubscription);
  };

  return (
    <Dialog
      open={show}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box>
        <Stack
          alignItems="center"
          justifyContent="center"
          p={24}
          pt={48}
          sx={{
            background: 'linear-gradient(0deg, rgba(255,255,255,1) 50%, rgba(197,190,249,0.6) 78%)',
            position: 'relative',
          }}
          minWidth={380}
        >
          <Stack justifyContent="center" alignItems="center">
            <Stack p={18} sx={{ backgroundColor: '#C5BEF9' }} borderRadius="50%" mb={32}>
              <SubIcon />
            </Stack>
          </Stack>
          <Stack gap={8} mb={48} alignItems="center" justifyContent="center">
            <Typography variant="h5" color="text.primary">
              Upgrade now
            </Typography>
            <Typography variant="subtitle2" color="text.primary" maxWidth={290} textAlign="center">
              You are on limited version which allows {userStore.upgradeInfo}. Upgrade your plan to
              get more experience.
            </Typography>
          </Stack>
          <Stack gap={16} justifyContent="stretch" width="100%">
            <Button variant="contained" onClick={() => handleUpgrade()}>
              Upgrade
            </Button>

            <Button variant="text" onClick={() => handleClose()}>
              Maybe later
            </Button>
          </Stack>
        </Stack>
      </Box>
    </Dialog>
  );
};

export default observer(UpgradePopup);

// const style = {
//   position: 'absolute' as 'absolute',
//   top: '50%',
//   left: '50%',
//   transform: 'translate(-50%, -50%)',
//   width: 400,
//   bgcolor: 'background.paper',
//   border: '2px solid #000',
//   boxShadow: 24,
//   p: 4,
// }
