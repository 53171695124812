import { Stack, Typography } from '@mui/material';
import { IContact } from '@shared/api/services/contact-service';
import { sortExperiences } from '@shared/libs/sortExperiences';
import { observer } from 'mobx-react-lite';
import { useEffect, useState } from 'react';
import { Button } from 'react-admin';

import { useContactsStore } from '../../../../../entities/contact-table';
import Experience from '../../../../../features/contacts/experience/experience';
import InfoBlock from '../../../../../features/contacts/info-block/info-block';

const WorkHistory = ({ contact }: { contact: IContact }) => {
  // const { iso3, name } = country
  const contactsStore = useContactsStore();
  const { company, job_title } = contact;

  const [slice, setSlice] = useState(true);
  const [count, setCount] = useState(0);

  useEffect(() => {
    setSlice(true);
  }, [contact]);

  useEffect(() => {
    if (contactsStore.currentUpdatedContact?.experiences) {
      setCount(contactsStore.currentUpdatedContact?.experiences.length);
    } else {
      setCount(contactsStore.currentWorkHistory?.length ?? 0);
    }
  }, [contactsStore.currentUpdatedContact?.experiences, contactsStore.currentWorkHistory]);

  const children = (
    <>
      {!contactsStore.currentUpdatedContact &&
        !contactsStore.currentWorkHistory?.length &&
        company && <Experience place={company} role={job_title} />}

      {contactsStore.currentUpdatedContact
        ? contactsStore.currentUpdatedContact.experiences.map((job, index) => (
            <Experience
              key={index}
              place={job.company}
              role={job.title}
              startDate={job.startDate}
              endDate={job.endDate ?? ''}
            />
          ))
        : sortExperiences(contactsStore.currentWorkHistory ?? [])
            .slice(0, slice ? 2 : undefined)
            .map((job, index) => (
              <Experience
                key={index}
                place={job.company?.name ?? ''}
                role={job.title?.name ?? ''}
                startDate={job.start_date}
                endDate={job.end_date ?? ''}
              />
            ))}
      {slice && count > 2 && (
        <Stack direction="row" justifyContent="center" alignItems="center" p={16} pt={0}>
          <Button
            sx={{ width: '100%' }}
            variant="contained"
            color="secondary"
            onClick={(e) => {
              e.stopPropagation();
              setSlice((prev) => !prev);
            }}
          >
            <Typography variant="subtitle1" color="primary.main">
              Show more
            </Typography>
          </Button>
        </Stack>
      )}
    </>
  );
  if (!contactsStore.currentUpdatedContact && !contactsStore.currentWorkHistory?.length && !company)
    return <></>;
  return <InfoBlock blockTitle={'Work history'}>{children}</InfoBlock>;
};

export default observer(WorkHistory);
