import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
//import { metaKeywords } from './currentCompany.meta'
import { ICompany } from '@shared/api/services/company-service';
import { getValidLink } from '@shared/libs/validations';

import { ReactComponent as FacebookIcon } from '../../../../../shared/assets/icons/iconFacebook.svg';
import { ReactComponent as LinkIcon } from '../../../../../shared/assets/icons/iconLink.svg';
import { ReactComponent as LinkedinIcon } from '../../../../../shared/assets/icons/iconLinkedin.svg';
import { ReactComponent as TwitterIcon } from '../../../../../shared/assets/icons/iconTwitter.svg';

export const CurrentCompany = ({ company }: { company: ICompany }) => {
  const { linkedin_url, website, twitter_url, facebook_url, industry, size } = company;

  const socialsCompany = [
    { social: 'Site', url: website, icon: LinkIcon },
    { social: 'Linkedin', url: linkedin_url, icon: LinkedinIcon },
    { social: 'Twitter', url: twitter_url, icon: TwitterIcon },
    { social: 'Facebook', url: facebook_url, icon: FacebookIcon },
  ];
  if (!company) return <></>;
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      border="1px solid #E7E8EF"
      borderRadius="8px"
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        p={16}
        pt={12}
        pb={12}
      >
        <Typography variant="h4" fontWeight={700}>
          Overview
        </Typography>
        {/* TODO: links render logic */}
        <Stack direction="row" gap={8} alignItems="flex-end">
          {socialsCompany.map(
            ({ url, social, icon: Icon }) =>
              url && (
                <Tooltip title={getValidLink(url)} key={social}>
                  <a href={getValidLink(url)} rel="noreferrer" target="_blank">
                    <IconButton sx={{ p: 4 }}>
                      <Icon />
                    </IconButton>
                  </a>
                </Tooltip>
              )
          )}
        </Stack>
      </Stack>
      <Stack
        width="100%"
        direction="column"
        justifyContent="space-between"
        gap={8}
        px={16}
        py={8}
        borderTop="1px solid #E7E8EF"
      >
        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          borderBottom="1px solid #E7E8EF"
          pb={8}
        >
          <Typography variant="subtitle2" color="customGray2.dark">
            Industry
          </Typography>
          <Typography variant="subtitle1" color="text.primary">
            {industry}
          </Typography>
        </Stack>

        <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          //borderBottom="1px solid #E7E8EF"
        >
          <Typography variant="subtitle2" color="customGray2.dark">
            Employees
          </Typography>
          <Typography variant="subtitle1" color="text.primary">
            {size}
          </Typography>
        </Stack>

        {/* <Stack
          width="100%"
          direction="row"
          justifyContent="space-between"
          borderBottom="1px solid #E7E8EF"
        >
          <Typography variant="subtitle2" color="customGray2.dark">
            Founded year
          </Typography>
          <Typography variant="subtitle1" color="text.primary">
            2020
          </Typography>
        </Stack>

        <Stack width="100%" direction="column" gap={16}>
          <Stack gap={4}>
            <Typography variant="subtitle2" color="customGray2.dark">
              Description
            </Typography>
            <Typography variant="subtitle2" color="text.primary" textAlign="justify">
              Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur eligendi, harum,
              error, delectus quasi mollitia voluptas molestiae assumenda perspiciatis eius
              exercitationem quaerat. Eaque, optio iure aspernatur nobis odit incidunt perspiciatis!
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit molestias sapiente
              iusto aperiam! Dolor, iusto sapiente? Animi perferendis ut officia expedita facilis
              obcaecati veniam voluptas rem. Tempore dolorum officiis...
              <Box
                component="span"
                sx={{
                  color: '#3626A7',
                  fontWeight: 600,
                  marginLeft: 8,
                }}
              >
                Show More
              </Box>
            </Typography>
          </Stack>
          <Stack gap={4}>
            <Typography variant="subtitle2" color="customGray2.dark">
              Keywords
            </Typography>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="left"
              gap={8}
              flexWrap="wrap"
            >
              {metaKeywords
                ? metaKeywords.map(({ id, key }) => (
                    <Box
                      key={id}
                      sx={{
                        whiteSpace: 'nowrap',
                        backgroundColor: '#F5F6F9',
                        paddingY: 2,
                        paddingX: 8,
                        borderRadius: 4,
                      }}
                    >
                      <Typography variant="subtitle2" color="customGray2.dark" fontWeight={500}>
                        {key}
                      </Typography>
                    </Box>
                  ))
                : 'There are no keywords'}
            </Stack>
          </Stack>
        </Stack> */}
      </Stack>
    </Stack>
  );
};
