import { Subscription, useSubscriptionStore } from '@entities/subscription';
import { CreditSlider, DataPlansChangeFilter, SummaryForm } from '@features/subscription';
import { Box, CircularProgress, Stack, Typography } from '@mui/material';
import { useUserProfile } from '@pages/user-profile/model/user-profile.store';
import { ReactComponent as ArrowIcon } from '@shared/assets/icons/arrowLeft.svg';
import { AppRoutes } from '@shared/interfaces';
import { Elements } from '@stripe/react-stripe-js';
import { flowResult } from 'mobx';
import { observer } from 'mobx-react-lite';
import { useEffect, useLayoutEffect, useReducer, useRef, useState } from 'react';
import { Link } from 'react-router-dom';

import { PlanStatus } from '@/shared/api/services/plan-service/plan.interfaces';
import stripePromise from '@/shared/api/stripePromise';
import usePreventRefreshing from '@/shared/hooks/usePreventRefreshing';

const StripePromise = stripePromise;

const SubscriptionPage = () => {
  const subscription = useSubscriptionStore();
  const userStore = useUserProfile();

  const divRef = useRef<HTMLDivElement>(null);

  const refIsShouldBlockPageRefreshing = useRef(false);
  usePreventRefreshing(refIsShouldBlockPageRefreshing);

  const refRequestId = useRef(0);
  const refCurrentRequestId = useRef(-1);
  const [shouldForceUpdate, forceUpdate] = useReducer((x) => x + 1, 0);
  useLayoutEffect(() => {
    const extendedCredits = {
      ...(subscription.sliderValues.email.count !== 0 && {
        email: subscription.sliderValues.email.count,
      }),
      ...(subscription.sliderValues.phone.count !== 0 && {
        phone: subscription.sliderValues.phone.count,
      }),
      ...(subscription.sliderValues.export.count !== 0 && {
        export: subscription.sliderValues.export.count,
      }),
      planId: subscription.selectedPlan?.id || '',
    };
    if (
      extendedCredits.planId !== '' &&
      extendedCredits.planId !== subscription.freePlan?.id
      // (extendedCredits.email || extendedCredits.phone || extendedCredits.export)
    ) {
      refRequestId.current += 1;
      flowResult(
        subscription.getExtendedPrices(extendedCredits, refRequestId.current, refCurrentRequestId)
      );
    }
    // eslint-disable-next-line
  }, [shouldForceUpdate, subscription.selectedPlan, subscription.currentSubscription]);

  useLayoutEffect(() => {
    if (
      subscription.currentSubscription === null ||
      subscription.currentSubscription.status === PlanStatus.CANCELED
    ) {
      subscription.selectedPlan = subscription.freePlan ?? null;
    }
    // eslint-disable-next-line
  }, [subscription.currentSubscription, subscription.freePlan]);

  const [srollToCustom, setScrollToCustom] = useState(false);
  useEffect(() => {
    if (divRef.current && srollToCustom) {
      divRef.current.scrollIntoView({ behavior: 'smooth' });
    }
    setScrollToCustom(false);
  }, [srollToCustom]);

  useEffect(() => {
    flowResult(subscription.getPlans());
    flowResult(userStore.getProfile());
  }, [subscription, userStore]);

  if (subscription.state === 'pending') {
    return (
      <Stack direction="row" alignItems="center" justifyContent="center" flexGrow={1}>
        <CircularProgress size={130} />
      </Stack>
    );
  }

  return (
    <>
      <Box m="0 auto" maxWidth={1300} width="100%" px={20}>
        <Box mt={16} sx={{ '& a': { textDecoration: 'none' } }}>
          <Link to={`${AppRoutes.Profile}`}>
            <Stack direction="row" alignItems="center" gap={7}>
              <Stack alignItems="center" justifyContent="center">
                <ArrowIcon />
              </Stack>
              <Typography variant="subtitle1" color="customGray2.dark">
                Back to profile
              </Typography>
            </Stack>
          </Link>
        </Box>
        <Stack alignItems="center" mb={56} mt={28}>
          <DataPlansChangeFilter />
        </Stack>
        <Stack direction="row" justifyContent="center" gap={32}>
          {!!subscription.filteredPlans.length &&
            subscription.filteredPlans.map((plan) => (
              <Subscription setScrollToCustom={setScrollToCustom} key={plan.id} plan={plan} />
            ))}
        </Stack>
        <Stack gap={64} mt={160}>
          <div ref={divRef}>
            <Typography variant="h2" textAlign="center">
              Customize plan
            </Typography>
          </div>
          <Stack gap={32}>
            <CreditSlider type="email" makeRequest={forceUpdate} />
            <CreditSlider type="phone" makeRequest={forceUpdate} />
          </Stack>
        </Stack>
        <Box mb={182} />
      </Box>
      <Elements stripe={StripePromise}>
        <SummaryForm refIsShouldBlockPageRefreshing={refIsShouldBlockPageRefreshing} />
      </Elements>
    </>
  );
};

export default observer(SubscriptionPage);
