import { AxiosInstance } from 'axios';

import withAuthorization from '../../withAuth';
import {
  ClientSecret,
  ExtendedPrices,
  ExtendedPricesDto,
  SubscribeRequest,
  Subscription,
} from './subscription.interfaces';

class SubscriptionService {
  private requestInstance: AxiosInstance;

  constructor(requestInstance: AxiosInstance) {
    this.requestInstance = requestInstance;
  }

  async getCurrentSubscription(): Promise<Subscription | string> {
    const { data } = await this.requestInstance.get<Subscription | string>('subscription');
    return data;
  }

  async subscribe(dto: SubscribeRequest): Promise<ClientSecret> {
    const { data } = await this.requestInstance.post<ClientSecret>('subscription/subscribe', dto);
    return data;
  }

  async getExtendedPrices(dto: ExtendedPricesDto | null): Promise<ExtendedPrices> {
    const { data } = await this.requestInstance.post<ExtendedPrices>(
      'subscription/extended-prices',
      dto
    );
    return data;
  }

  async changePlan(dto: SubscribeRequest): Promise<ClientSecret> {
    const { data } = await this.requestInstance.post<ClientSecret>('subscription/change-plan', dto);
    return data;
  }

  async cancel(): Promise<void> {
    await this.requestInstance.post('subscription/cancel');
  }
}

export const subscriptionService = new SubscriptionService(withAuthorization);
