import { Drawer, IconButton } from '@mui/material';
import { observer } from 'mobx-react-lite';
import { useEffect, useRef, useState } from 'react';
import { ListBase } from 'react-admin';

import { CompaniesDataGrid } from '../../../entities/companies-table';
import useCompaniesStore from '../../../entities/companies-table/model/companies-table.store';
import UpdateWrapper from '../../../entities/update-wrapper/update-wrapper';
import { ReactComponent as CloseIcon } from '../../../shared/assets/icons/iconClose.svg';
import { ContactLayout } from '../../../widgets/contact-layout';

const CompaniesPage = () => {
  // const contactButton = {
  //   EmailButton: EmailButton,
  //   PhoneButton: PhoneButton,
  // }

  const companies = useCompaniesStore();

  const tableRef = useRef<null | HTMLTableElement>(null);

  const drawerRef = useRef<null | HTMLDivElement>(null);

  const isSideBarOpen = Boolean(companies.currentContact);

  const onSideBarCloseHandler = () => {
    companies.setCurrentContact(null);
  };

  useEffect(() => {
    const onOutsideClickHandler = (e: Event) => {
      if (
        tableRef.current &&
        drawerRef.current &&
        !tableRef.current.contains(e.target as HTMLElement) &&
        !drawerRef.current.contains(e.target as HTMLElement)
      ) {
        companies.setCurrentContact(null);
      }
    };
    document.addEventListener('click', onOutsideClickHandler);
    return () => {
      document.removeEventListener('click', onOutsideClickHandler);
    };
  }, [companies]);

  const [updating, setUpdating] = useState(false);
  const [showSaved, setShowSaved] = useState(false);
  console.log(updating);

  return (
    <>
      <UpdateWrapper setUpdating={setUpdating} isOpen={isSideBarOpen}>
        <ListBase perPage={25} resource="companies">
          <ContactLayout setShowSaved={setShowSaved} showSaved={showSaved} isCompanies={true}>
            <CompaniesDataGrid ref={tableRef} />
          </ContactLayout>
        </ListBase>

        <Drawer
          anchor="right"
          open={isSideBarOpen}
          onClose={onSideBarCloseHandler}
          ref={drawerRef}
          variant="persistent"
          PaperProps={{
            sx: {
              boxShadow: '-4px 0px 20px rgba(111, 113, 122, 0.2)',
              overflowY: 'visible',
            },
          }}
        >
          <IconButton
            onClick={onSideBarCloseHandler}
            sx={{
              border: '2px solid #EFF0F1',
              position: 'absolute',
              padding: 6,
              top: 8,
              left: -16,
              zIndex: 20,
              backgroundColor: 'white',
              ':hover': {
                backgroundColor: '#F5F6F9',
              },
            }}
          >
            <CloseIcon />
          </IconButton>
          {/* <ContactSidebar contact={companies.currentContact!} updating={updating} /> */}
        </Drawer>
        {/* <UpgradePopup /> */}
      </UpdateWrapper>
    </>
  );
};

export default observer(CompaniesPage);
