import { IconButton, Stack, Tooltip, Typography } from '@mui/material';
import { IContact } from '@shared/api/services/contact-service';
import { AppRoutes } from '@shared/interfaces';
import { getValidLink } from '@shared/libs/validations';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-admin';

//import { useContactFilter } from '../../../../../features/contacts/filters/hooks/filter-context'
import { useContactsStore } from '../../../../../entities/contact-table';
import { ReactComponent as FacebookIcon } from '../../../../../shared/assets/icons/iconFacebook.svg';
import { ReactComponent as LinkIcon } from '../../../../../shared/assets/icons/iconLink.svg';
import { ReactComponent as LinkedinIcon } from '../../../../../shared/assets/icons/iconLinkedin.svg';
import { ReactComponent as TwitterIcon } from '../../../../../shared/assets/icons/iconTwitter.svg';

const getFirstUppercase = (value: string) => {
  return value && value.length ? value[0].toLocaleUpperCase() + value.slice(1) : value;
};

const CurrentCompany = ({ contact }: { contact: IContact }) => {
  const {
    company_size,
    industry,
    company_linkedin_url,
    company_twitter_url,
    company_facebook_url,
    company_url,
    company,
  } = contact;

  const contactsStore = useContactsStore();

  const currentWorkplace = contactsStore.currentWorkHistory?.find((job) => job.is_primary);

  const currentUpdatedWorkplace = contactsStore.currentUpdatedContact?.experiences.find((job) => {
    const end = new Date(job.endDate);
    const now = new Date();
    return (
      end.getDate() === now.getDate() &&
      end.getMonth() === now.getMonth() &&
      end.getFullYear() === now.getFullYear()
    );
  });

  const currentCompanyName = getFirstUppercase(
    currentUpdatedWorkplace
      ? currentUpdatedWorkplace.company
      : contactsStore.currentUpdatedContact
        ? contactsStore.currentUpdatedContact.company
        : currentWorkplace
          ? currentWorkplace.company?.name
          : company
  );

  const currentIndustry = getFirstUppercase(
    currentWorkplace ? currentWorkplace.company?.industry : industry
  );
  const currentSize = getFirstUppercase(
    currentWorkplace ? currentWorkplace.company?.size : company_size
  );

  // const currentCompanySize = contactsStore.currentUpdatedContact
  //   ? contactsStore.currentUpdatedContact.
  //   : company_size

  const socialsCompany = [
    {
      social: 'Site',
      url: currentWorkplace ? currentWorkplace.company?.website : company_url,
      icon: LinkIcon,
    },
    {
      social: 'Linkedin',
      url: currentWorkplace ? currentWorkplace.company?.linkedin_id : company_linkedin_url,
      icon: LinkedinIcon,
    },
    {
      social: 'Twitter',
      url: currentWorkplace ? currentWorkplace.company?.twitter_url : company_twitter_url,
      icon: TwitterIcon,
    },
    {
      social: 'Facebook',
      url: currentWorkplace ? currentWorkplace.company?.facebook_url : company_facebook_url,
      icon: FacebookIcon,
    },
  ];
  // ? [
  //     { social: 'Site', url: contactsStore.currentContact.company_url, icon: LinkIcon },
  //     { social: 'Linkedin', url: contactsStore.currentContact.company_linkedin_url, icon: LinkedinIcon },
  //     { social: 'Twitter', url: contactsStore.currentContact.company_twitter_url, icon: TwitterIcon },
  //     { social: 'Facebook', url: contactsStore.currentContact.company_facebook_url, icon: FacebookIcon },
  //   ]
  // : [

  //const filter = useContactFilter()

  // const setIndustryFilter = () => {
  //   filter.resetAll?.()
  //   filter.setFilterInputs?.(prev => ({
  //     ...prev,
  //     industries: industry,
  //   }))
  //   filter.applyFilter?.()
  // }

  return (
    <>
      {currentCompanyName && (
        <Stack
          direction="column"
          justifyContent="flex-start"
          border={(theme) => `1px solid ${theme.palette.customGray1.light}`}
          borderRadius="8px"
        >
          <Stack
            direction="row"
            justifyContent="space-between"
            alignItems="center"
            p={16}
            pt={12}
            pb={12}
          >
            {currentWorkplace?.company.id ? (
              <Link
                to={`${AppRoutes.Company}/${currentWorkplace?.company.id}/show`}
                onClick={(e) => e.stopPropagation()}
              >
                <Typography variant="h4" color="primary.main" fontWeight={700}>
                  {currentCompanyName}
                </Typography>
              </Link>
            ) : (
              <Typography variant="h4" color="text.primary" fontWeight={700}>
                {currentCompanyName}
              </Typography>
            )}
            <Stack direction="row" gap={8} alignItems="flex-end">
              {socialsCompany.map(
                ({ url, social, icon: Icon }) =>
                  url && (
                    <Tooltip title={getValidLink(url)} key={social}>
                      <a href={getValidLink(url)} rel="noreferrer" target="_blank">
                        <IconButton sx={{ p: 4 }}>
                          <Icon />
                        </IconButton>
                      </a>
                    </Tooltip>
                  )
              )}
            </Stack>
          </Stack>
          <Stack
            width="100%"
            direction="column"
            justifyContent="space-between"
            gap={8}
            px={16}
            py={8}
            borderTop={(theme) => `1px solid ${theme.palette.customGray1.light}`}
          >
            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              borderBottom={(theme) => `1px solid ${theme.palette.customGray1.light}`}
              pb={8}
            >
              <Typography variant="subtitle2" color="customGray2.dark">
                Industry
              </Typography>
              {/* <Box onClick={() => setIndustryFilter()}> */}
              <Typography variant="subtitle1" color="text.primary">
                {currentIndustry}
              </Typography>
              {/* </Box> */}
            </Stack>

            <Stack
              width="100%"
              direction="row"
              justifyContent="space-between"
              pb={8}
              borderBottom={
                currentWorkplace?.company?.founded && currentWorkplace?.company?.founded.length
                  ? (theme) => `1px solid ${theme.palette.customGray1.light}`
                  : 'none'
              }
            >
              <Typography variant="subtitle2" color="customGray2.dark">
                Employees
              </Typography>
              <Typography variant="subtitle1" color="text.primary">
                {currentSize} {/*Find Leads */}
              </Typography>
            </Stack>

            {currentWorkplace?.company?.founded && (
              <Stack
                width="100%"
                direction="row"
                justifyContent="space-between"
                pb={8}
                borderBottom={(theme) => `1px solid ${theme.palette.customGray1.light}`}
              >
                <Typography variant="subtitle2" color="customGray2.dark">
                  Founded year
                </Typography>
                <Typography variant="subtitle1" color="text.primary">
                  {currentWorkplace.company.founded}
                </Typography>
              </Stack>
            )}

            {/* <Stack width="100%" direction="column" gap={16}>
              <Stack gap={4}>
                <Typography variant="subtitle2" color="customGray2.dark">
                  Description
                </Typography>
                <Typography variant="subtitle2" color="text.primary" textAlign="justify">
                  Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aspernatur eligendi,
                  harum, error, delectus quasi mollitia voluptas molestiae assumenda perspiciatis
                  eius exercitationem quaerat. Eaque, optio iure aspernatur nobis odit incidunt
                  perspiciatis! Lorem ipsum dolor sit amet consectetur adipisicing elit. Impedit
                  molestias sapiente iusto aperiam! Dolor, iusto sapiente? Animi perferendis ut
                  officia expedita facilis obcaecati veniam voluptas rem. Tempore dolorum
                  officiis...
                  <Box
                    component="span"
                    sx={{
                      color: '#3626A7',
                      fontWeight: 600,
                      marginLeft: 8,
                    }}
                  >
                    Show More
                  </Box>
                </Typography>
              </Stack>
              <Stack gap={4}>
                <Typography variant="subtitle2" color="customGray2.dark">
                  Keywords
                </Typography>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="left"
                  gap={8}
                  flexWrap="wrap"
                >
                  {metaKeywords
                    ? metaKeywords.map(({ id, key }) => (
                        <Box
                          key={id}
                          sx={{
                            whiteSpace: 'nowrap',
                            backgroundColor: '#F5F6F9',
                            paddingY: 2,
                            paddingX: 8,
                            borderRadius: 4,
                          }}
                        >
                          <Typography variant="subtitle2" color="customGray2.dark" fontWeight={500}>
                            {key}
                          </Typography>
                        </Box>
                      ))
                    : 'There are no keywords :('}
                </Stack>
              </Stack>
            </Stack> */}
          </Stack>
        </Stack>
      )}
    </>
  );
};

export default observer(CurrentCompany);
