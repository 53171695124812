enum AppRoutes {
  Base = '/',
  Login = '/login',
  ForgotPassword = '/forgot-password',
  SignUp = '/sign-up',
  CheckEmail = '/check-email',
  Verification = '/verify',
  ResetPassword = '/reset-password',
  Contacts = '/contacts',
  Company = '/company',
  Profile = '/profile',
  ProfileSubscription = '/profile/subscription',
  SubscriptionPayment = '/profile/subscription/payment',
  Companies = '/companies',
}

export { AppRoutes };
